/* eslint-disable no-dupe-keys */


import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import axios, * as others from 'axios';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
// const axios = require('axios');


function HomeScreen() {
  const navigate = useNavigate();

  const styles = useStyles()

  const [userList, setUserList] = useState([])


  useEffect(() => {
      getUsersData()
  }, [])


  const getUsersData = () => {
      // const token = localStorage.getItem('admin_id_token')
      // const data = {
      //     'admin_id_token': token
      // }
      const token = JSON.parse(localStorage.getItem('adminDetails'));
    const data = {
      admin_id_token: token.admin_id_token,
    };

      try {
          axios.post(`https://chatbot.digitallandscape.com.pk:5030/get_users`, data)
              .then((response) => {
                  // console.log(JSON.stringify(response))
                  if (response?.status === 200) {
                      if (response.data?.is_valid_token === true) {
                          setUserList(response.data?.data)
                          // console.log("Data is",response.data)
                      } else {
                        localStorage.setItem('adminDetails'," ")
                        // navigate('../LoginScreen')
                        navigate(`../login`)
                      }
                  }
              })
              .catch((error) => {
                  // // console.log(error);
                  // alert(error.message)
                  navigate(`/dashboard/app`)
              });
      } catch (error) {
          // console.log('Error : ', error)
          alert(JSON.stringify(error))
      }


  }

  const navigateToChatScreen = (item,userdetails) => {
      localStorage.setItem('userDetails', JSON.stringify(userdetails))
      localStorage.setItem('chat_id', item)
      // navigate(`../ChatScreen`)
      navigate(`../sessions`)
      // navigate(`../ChatScreen/${item}`)
  }


  const logout = () => {
      localStorage.setItem('admin_id_token', '')
      navigate('../LoginScreen')
  }


  const listItems = userList.map((item, index) =>
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
      <li key={index} className={styles.listItem} onClick={() => {
          const userdetails={
              user_id:item?.user_id,
              name:item?.name,
              age:item?.age,
              marital_staus:item?.marital_staus,
              diagnosis:item?.diagnosis,
          }
           navigateToChatScreen(item?.user_id,userdetails) 
           }}>
          {/* {JSON.stringify(item)} */}
          <div className={styles.tableItemContainer}>  
              <h1 className={styles.tableItem}>{item?.name}</h1>
              <h1 className={styles.tableItem}>{item?.age}</h1>
              <h1 className={styles.tableItem}>{item?.marital_staus}</h1>
              <h1 className={styles.tableItem}>{item?.diagnosis}</h1>
              <h1 className={styles.tableItem}>{item?.start_time}</h1>
          </div>
      </li>
  );

  return (
    <Page title="User">
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          User
        </Typography>
        {/* <Button variant="contained" component={RouterLink} to="/dashboard/app"  startIcon={<Iconify icon="ion:navigate" />}>
          Dashboard
        </Button> */}
      </Stack>
      <div className={styles.mainContainer}>
          <div className={styles.tableHeader}>  
              <h1 className={styles.tableHeaderItem}>Name</h1>
              <h1 className={styles.tableHeaderItem}>Age</h1>
              <h1 className={styles.tableHeaderItem}>Marital Status</h1>
              <h1 className={styles.tableHeaderItem}>Diagnosis</h1>
              <h1 className={styles.tableHeaderItem}>Time</h1>
          </div>
          <ul className={styles.list}>
              {listItems}
          </ul>
      </div>
      
    </Container>
  </Page>
  );
}

const useStyles = createUseStyles({
  mainContainer: {
    width: '100%',
    height: '100%',
    flex: 1,
  },
  header: {
    fontSize: '4vh',
    marginLeft: '2vw',
  },
  list: {
    width: '100%',
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  listItem: {
    width: '100%',
    backgroundColor: '#429EFA50',
    borderRadius: 10,
    cursor: 'pointer',
    marginTop: '2vh',
    padding: '2vh',
    webkitBoxShadow: "1px 4px 4px #00000050",
    mozBoxShadow: "1px 4px 4px #00000050",
    boxShadow: "1px 4px 4px #00000050",
    transition: "all 0.5s ease-in-out",
    "&:-moz-transition": "all 0.5s ease-in-out",
    "&:-webkit-transition": "all 0.5s ease-in-out",
    "&:-o-transition": "all 0.5s ease-in-out",
    '&:hover': {
        transition: "all 0.5s ease-in-out",
        "&:-moz-transition": "all 0.5s ease-in-out",
        "&:-webkit-transition": "all 0.5s ease-in-out",
        "&:-o-transition": "all 0.5s ease-in-out",
        backgroundColor: '#429EFA90',
    },
  },
  tableHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1vw',
    backgroundColor: '#429EFA90',
    alignSelf: 'center',
    borderRadius: 10,
  },
  tableHeaderItem: {
    flex: 1,
    fontSize: '12pt',
    color:"#FFFFFF",
  },
  tableItemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    background: "#67B3FF 0% 0% no-repeat padding-box",
    alignItems: 'center',
    padding: '1vw',
    color:"#FFFFFF",
    // backgroundColor: '#429EFA20',
    alignSelf: 'center',
    borderRadius: 10,
    cursor: 'pointer',
    // margin: '2vh',
    // padding: '2vh',
    webkitBoxShadow: "1px 4px 4px #00000050",
    mozBoxShadow: "1px 4px 4px #00000050",
    boxShadow: "1px 4px 4px #00000050",
    transition: "all 0.5s ease-in-out",
    '&:-moz-transition': 'all 0.5s ease-in-out',
    '&:-webkit-transition': 'all 0.5s ease-in-out',
    '&:-o-transition': 'all 0.5s ease-in-out',
    '&:hover': {
      transition: 'all 0.5s ease-in-out',
      '&:-moz-transition': 'all 0.5s ease-in-out',
      '&:-webkit-transition': 'all 0.5s ease-in-out',
      '&:-o-transition': 'all 0.5s ease-in-out',
      backgroundColor: '#429EFA50',
    },
  },
  tableItem: {
    flex: 1,
    fontSize: '10pt',
  },
});
export default HomeScreen;
